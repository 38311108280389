<template>
</template>

<script>
</script>

<style>
</style>
<template>
	<div class="box">
		<div class="left">

		</div>
		<div class="right">
			<div style="padding:0 150px;">
				<div class="logo">
					<!-- <img src="https://probe.bjmantis.net/msp/front/8888/8888_home_logo.png" alt=""> -->
				</div>

				<div class="user">
					<el-form size="small" :model="oAddForm" :rules="rules" ref="ruleForm">
						<el-form-item prop="user_name">
							<div class="user">
								<el-input v-model="oAddForm.user_name" size="small" placeholder="请输入用户名" @keyup.enter.native="doLogin"></el-input>
							</div>

						</el-form-item>
						<el-form-item prop="pass_word">
							<div class="user">
								<el-input type="password" v-model="oAddForm.pass_word" size="small" placeholder="请输入密码" @keyup.enter.native="doLogin">
								</el-input>
							</div>
						</el-form-item>
						<el-form-item>
							<div>
								<el-button size="small" class="user btn" @click="submitForm">登录</el-button>
							</div>
						</el-form-item>
					</el-form>
				</div>
				<div class="logo2">
					<!-- <img src="https://probe.bjmantis.net/msp/front/8888/8888_home_logo.png" alt=""> -->
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import oIndex from "@/js/login/login.js";
	import oSystem from "@/js/system/index.js";
	import Watermark from '@/libs/watermark.js';
	export default {
		data() {
			return {
				oAddForm: {
					user_name: '',
					pass_word: '',
				},
				rules: {
					user_name: [{
							required: true,
							message: '请输入用户名',
							trigger: 'blur'
						},
						{
							min: 3,
							max: 16,
							message: '长度在 3 到 16 个字符',
							trigger: 'blur'
						}
					],
					pass_word: [{
							required: true,
							message: '请输入密码',
							trigger: 'blur'
						},
						{
							min: 3,
							max: 16,
							message: '长度在 3 到 16 个字符',
							trigger: 'blur'
						}
					],
				}
			}
		},
		created() {
			Watermark.set('')
		},
		methods: {
			submitForm() {
				this.$refs.ruleForm.validate((vali) => {
					if (vali) {
						let data = {
							username: this.oAddForm.user_name,
							password: this.oAddForm.pass_word,
						};
						oIndex.login(data).then((res) => {
							oSystem.getUserInfo({}).then((res) => {
								console.log(res)
								this.$message.success('登录成功');
								this.$router.push('/main')
							});
						});

						// this.$router.push('/')
					} else {
						return false;
					}
				});
			},
			//回车登录
			doLogin() {
			  this.submitForm();
			},
		}
	}
</script>

<style lang="less" scoped>
	.box {
		margin: 0;
		padding: 0;
		display: flex;
		width: 100%;
		height: 100%;

		.left {
			display: flex;
			width: 70%;
			background: url('https://ylzdedu.n8.bjmantis.cn/static/media/login_background.46c65846.jpg') center center / 100% 100% no-repeat;
		}

		.right {
			flex: 1;
			height: 100vh;
			background-color: #0c2b4a;
			margin-left: auto;
			display: flex;
			flex-direction: column;
			align-items: center;
			flex-wrap: wrap;
			
			.logo {
				margin-top: 60%;
				margin-bottom: 50px;
				padding: 15px;
				width: 270px;
				height: 68px;
				img {
					width: 100%;
				}
			}

			.logo2 {
				margin-top: 70px auto;
				
				// margin-bottom: 50px;
				padding: 50px;
				text-align: center;
				width: 200px;
				height: 50px;
				img {

					min-width: 200px;
					max-width: 200px;
				}
			}

			.user {
				width: 100%;
			}

			.btn {
				background-color: #28d1a1;
				border: 0;
				color: #fff;
				font-size: 15px;
				letter-spacing: 12px
			}
		}
	}
</style>
